import React from "react";
import { Row, Col, Typography } from "antd";
import { Link } from "gatsby";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import CtaSecondary from "../../components/Buttons/secondary-cta";
import ogImage from "../../images/global_assets/og-image.png";
import LegalStyles from "../../components/Layout/LegalStyles";

const { Title } = Typography;

const MerchantTerms = () => {
  return (
    <Layout className="consumer-privacy">
      <SEO
        title="SpotOn | Privacy &amp; Legal"
        description="At SpotOn, we respect your privacy and are committed to being upfront and transparent. Please review our terms of services and privacy policies for more information."
        image={`https://spoton.com/${ogImage}`}
      />
      {/* <Affix offsetTop={0}> */}
      <div style={{ marginTop: 25 }}>
        <CtaSecondary target="/legal" ctaTitle="Legal" reverseIcon />
      </div>
      {/* </Affix> */}
      <main className="consumer-privacy__data">
        <Row>
          <Col lg={16} sm={24}>
            <div style={{ marginBottom: 80 }}>
              <Title level={1}>Gift Card Program Terms and Conditions.</Title>
              <p>
                SpotOn Transact LLC (SpotOn) has chosen ECard Systems, LLC (ECS)
                to fulfill gift card program orders. Upon ordering a gift card
                program, merchant understands SpotOn will refer merchant to ECS.
                ECS will decide, in ECS&apos; discretion, whether to fulfill
                merchant&apos;s order. If ECS accepts the order, merchant will
                enter into a direct contractual relationship with ECS for ECS to
                fulfill the order. ECS will directly invoice merchant for the
                order, and merchant agrees it will pay ECS for the order.
                Merchant agrees SpotOn is neither a party to merchant&apos;s
                agreement with ECS, nor obligated to fulfill merchant&apos;s
                gift card program order.
              </p>
              <br />
              <br />
              <br />
              <br />
            </div>
          </Col>
          <Col lg={8} sm={24}>
            <div className="aside">
              <Title level={3}>Related Documents</Title>
              <CtaSecondary
                target="/legal/consumer-privacy"
                ctaTitle="Consumer Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-privacy"
                ctaTitle="Merchant Privacy"
              />
              <CtaSecondary
                target="/payments-terms.pdf"
                ctaTitle="Payments Terms"
              />
              <CtaSecondary target="/legal/user-terms" ctaTitle="User Terms" />
              <CtaSecondary
                target="/legal/point-of-sale-terms"
                ctaTitle="Point-of-Sale Terms"
              />
              <CtaSecondary
                target="/legal/website-terms"
                ctaTitle="Website Terms"
              />
              <CtaSecondary
                target="/legal/reserve-terms"
                ctaTitle=" Reserve Terms"
              />
              <CtaSecondary
                target="/legal/teamwork-terms"
                ctaTitle="SpotOn Teamwork Terms"
              />
            </div>
          </Col>
        </Row>
      </main>

      <LegalStyles />
    </Layout>
  );
};

export default MerchantTerms;
